import React from 'react';
import { connect } from 'react-redux';
import Img from "gatsby-image"
import Layout from '../components/layout';
import SEO from '../components/seo';
import DepthLayer from '../components/parallax/DepthLayer';
import { updateCursor, updateWindow } from '../state/state-movement';
import { graphql } from 'gatsby';
import Contact from '../svg/contact-icon.svg'
import renderHTML from 'react-render-html';
import Logo from '../svg/white-logo.svg'
import Arrow from '../svg/left-arrow.svg'
import AniLink from "gatsby-plugin-transition-link/AniLink";

class ContactPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contentActive: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({contentActive: true});
    }, 100);
  }

  render () {
    let data = this.props.data.contact;
    let depth = this.props.depth;
    let movement = this.props.movement;

    console.log(data.socialItems);
    


    return (
      <>
        <SEO title="Contact" bodyType="" description={data.seoDescription}/>
        <Layout 
          transitionStatus={this.props.transitionStatus}
          transitionDirection={this.props.current.state.direction}
          class={`contact`}
        >
          <div className={`content ${this.state.contentActive && 'content-active'}`}>
            <div className="content-header">
              <div className="content-header__icon">
                <Contact />
              </div>
              <h1 className="content-header__title">Contact</h1>
            </div>
            <div className="content-body">
              <div className="content-body__left">
                {renderHTML(data.contactText)}
              </div>
              <div className="content-body__right">
                <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="Telekinetik Contact Form" className="contact-form">
                  <input type="hidden" name="bot-field"/>
                  <input type="hidden" name="form-name" value="Telekinetik Contact Form"/>
                  <label>
                    Name
                    <input type="text" name="name" id="name" placeholder="What should we call you?" required/>
                  </label>
                  <label>
                    Email
                    <input type="email" name="email" id="email" placeholder="What's your email?" required/>
                  </label>
                  <label>
                    Message
                    <textarea name="message" id="message" rows="5" placeholder="What you wanna talk about?"/>
                  </label>
                  <button type="submit">Submit</button>
                </form>
              </div>
              <div className="content-body__full-width">
                <div className="content-body__social-links">
                  <p className="content-body__social-title">Social links:</p>
                  {data.socialItems.map(socialItem => (
                    <a href={socialItem.linkUrl} target="_blank" className="content-body__social-link">
                      <img src={socialItem.icon.url} alt=""/>
                      {/* <span>{socialItem.linkText}</span> */}
                    </a>
                  ))}
                </div>
              </div>
              <div className="content-body__bottom-link contact">
                <AniLink to={`/`} fade duration={1}>
                  <span className="content-link__text">Show me that awesome homepage again!</span>
                  <Arrow/>
                </AniLink>
              </div>              
            </div>
            <Logo className="content-logo gallery"/>
          </div>
        </Layout>
      </>
    )
  }
}

export const query = graphql`
  query {
    contact: datoCmsContact {
      seoDescription
      contactText
      socialItems {
        linkUrl
        linkText
        icon {
          url
        }
      }
    }
  }
`

export default connect(
  state => ({ 
    window: state.movementState.windowDimensions,
    depth: state.movementState.depthLevels,
    movement: state.movementState,
  }),
  dispatch => ({ 
    updateCursor: cursorObj => dispatch(updateCursor(cursorObj)),
    updateWindow: windowObj => dispatch(updateWindow(windowObj)),
  }),
)(ContactPage);